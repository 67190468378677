<template>
    <div class="">
        <div class="container">
            <div v-if="checkingDetails || loadingPageData">
                <div class="spacer s30"></div>
                <h3>
                    {{ status }}
                </h3>
            </div>

            <div class="spacer s40 desktop"></div>

            <div v-if="formLoaded">
                <div v-if="accessAllowed">
                    <div v-if="requiresLogin">
                        <div v-if="userHasAccess">
                            display web page with logged in user content
                        </div>
                        <div v-else>
                            <div class="spacer s30"></div>
                            {{ formMessage }} <br />
                            <div class="spacer s30"></div>
                            <div v-if="!isLoggedIn" class="button is-primary" @click="showLogin()">Login required</div>
                        </div>
                    </div>
                    <div v-else>
                        <h2>Welcome {{ formData.contactName }}!</h2>
                        <!-- <hr />
                        <div>{{ formData }}</div> -->
                        <!-- <hr /> -->

                        <div class="intro">
                            <p>Thanks for your interest in OffshoreSMS. Below are the next steps that we need to work through together for you to become a happy OffshoreSMS user.</p>
                            <p>You can also browse our online documentation at <a href="https://docs.offshoresms.com.au" target="_blank">docs.offshoresms.com.au</a></p>
                        </div>

                        <div class="spacer s30"></div>

                        <div class="box" @click="toggle('one')" :class="{ visible: toggled.one }">
                            <div class="icon-label">
                                <div class="icon-img">
                                    <img src="@/assets/icons/target.jpg" />
                                </div>
                                <div>1. Make that initial enquiry</div>
                            </div>
                            <span class="status done"><font-awesome-icon icon="check-square"/></span>
                            <div class="sub-content">
                                <p class="blue">
                                    You did it! You're well on the way to joining thousands of commercial marine operators, skippers and crews that use OffshoreSMS every day to manage their vessels
                                    with zero paper, quickly and easily.
                                </p>
                            </div>
                        </div>

                        <div class="box" @click="toggle('two')" :class="{ visible: toggled.two }">
                            <div class="icon-label">
                                <div class="icon-img">
                                    <img src="@/assets/icons/checklist.jpg" />
                                </div>
                                <div>2. Provide us with some more information</div>
                            </div>
                            <span class="status" :class="{ done: formComplete }"><font-awesome-icon :icon="formComplete ? 'check-square' : 'square'"/></span>
                            <div class="sub-content">
                                <p class="blue">
                                    By completing the following information fields, we can make sure that we are prepared for our first chat and can offer you the solutions best suited to your
                                    operation.
                                </p>
                                <div class="spacer s10"></div>
                                <div class="form-section">
                                    <label class="label" :class="{ red: fleetInfo.contactName == '' }">Your name <span v-if="fleetInfo.contactName == ''">*</span></label>
                                    <input class="input padded" type="text" placeholder="Type your name.." v-model="fleetInfo.contactName" maxlength="100" @input="formUpdated = true" />
                                </div>
                                <div class="form-section">
                                    <label class="label" :class="{ red: fleetInfo.email == '' }">Your email address <span v-if="fleetInfo.email == ''">*</span></label>
                                    <input class="input padded" type="text" placeholder="Type your email.." v-model="fleetInfo.email" maxlength="100" @input="formUpdated = true" />
                                </div>
                                <div class="form-section">
                                    <label class="label" :class="{ red: fleetInfo.fleetName == '' }">Business or fleet name <span v-if="fleetInfo.fleetName == ''">*</span></label>
                                    <input class="input padded" type="text" placeholder="Type your business name.." v-model="fleetInfo.fleetName" maxlength="100" @input="formUpdated = true" />
                                </div>
                                <div class="form-section">
                                    <label class="label" :class="{ red: fleetInfo.location == '' }">Primary location / town <span v-if="fleetInfo.location == ''">*</span></label>
                                    <input class="input padded" type="text" placeholder="Type your location.." v-model="fleetInfo.location" maxlength="100" @input="formUpdated = true" />
                                </div>
                                <div class="form-section">
                                    <label class="label" :class="{ red: fleetInfo.assets == '' }">Number of vessels or marine assets <span v-if="fleetInfo.assets == ''">*</span></label>
                                    <div class="select">
                                        <select v-model="fleetInfo.assets" @change="formUpdated = true">
                                            <option value="" disabled>Choose an option</option>
                                            <option v-for="(option, ix) in assetOptions" :value="option" :key="`asset-${ix}`">{{ option }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-section">
                                    <label class="label" :class="{ red: fleetInfo.people == '' }">Number of marine crew / team members <span v-if="fleetInfo.people == ''">*</span></label>
                                    <div class="select">
                                        <select v-model="fleetInfo.people" @change="formUpdated = true">
                                            <option value="" disabled>Choose an option</option>
                                            <option v-for="(option, ix) in peopleOptions" :value="option" :key="`people-${ix}`">{{ option }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-section">
                                    <label class="label">Your main industry</label>
                                    <input class="input padded" type="text" placeholder="Type your industry.." v-model="fleetInfo.industry" maxlength="100" @input="formUpdated = true" />
                                </div>
                                <div class="form-section">
                                    <div class="checkbox-wrapper">
                                        <div class="field" v-for="(option, ix) in features" :key="`check-${ix}`">
                                            <input class="is-checkradio" type="checkbox" :id="option" :value="option" v-model="fleetInfo.features" @input="formUpdated = true" />
                                            <label :for="option">{{ option }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-section">
                                    <label class="label">Any notes</label>
                                    <textarea class="textarea padded" placeholder="Type anything.." v-model="fleetInfo.notes" maxlength="1000"></textarea>
                                </div>
                                <div class="buttons">
                                    <button :disabled="!formUpdated" class="button is-success" @click="updateForm()">Update My Information</button>
                                </div>
                            </div>
                        </div>

                        <div class="box" @click="toggle('three')" :class="{ visible: toggled.three }">
                            <div class="icon-label">
                                <div class="icon-img">
                                    <img src="@/assets/icons/crew.jpg" />
                                </div>
                                <div>3. Create an OffshoreSMS user profile</div>
                            </div>
                            <span class="status" :class="{ done: isLoggedIn }"><font-awesome-icon :icon="isLoggedIn ? 'check-square' : 'square'"/></span>
                            <div class="sub-content">
                                <p v-if="!isLoggedIn">The best way to really see how OffshoreSMS works is to create your own free user profile so we can set you up with a free demo.</p>
                                <p v-else>Nice work! It looks like you already have an OffshoreSMS user profile.</p>
                                <div class="spacer s20"></div>
                                <div class="buttons" v-if="!isLoggedIn">
                                    <a href="https://dash.offshoresms.com.au" target="_blank" class="button is-primary">Create a Free Profile</a>
                                </div>
                                <div class="buttons" v-else>
                                    <a href="https://dash.offshoresms.com.au" target="_blank" class="button is-info">Go to your Dashboard</a>
                                </div>
                            </div>
                        </div>

                        <div class="box" @click="toggle('four')" :class="{ visible: toggled.four }">
                            <div class="icon-label">
                                <div class="icon-img">
                                    <img src="@/assets/icons/devices.jpg" />
                                </div>
                                <div>4. Set up a Demo fleet to see how it works</div>
                            </div>
                            <span class="status" :class="{ done: trialActive }"><font-awesome-icon :icon="trialActive ? 'check-square' : 'square'"/></span>
                            <div class="sub-content">
                                <div v-if="!trialActive">
                                    <p class="blue" v-if="!trialRequested">Creating a new fleet with a test vessel or other marine asset is the best way to really see how OffshoreSMS works.</p>
                                    <p class="blue" v-if="trialRequested">We are setting up your new fleet trial and will be in touch shortly.</p>
                                    <p class="orange" v-if="!formComplete">Before we can set up a fleet we really do need the information form completed.</p>
                                </div>
                                <div v-if="trialActive">
                                    <p class="blue">Your demo fleet is live. Click the button below to log into your fleet on the dashboard.</p>
                                </div>
                                <div v-if="demoBooked">
                                    <p class="green">We have a live demo booked on {{ simpleDate(demoDate.toDate()) }}.</p>
                                </div>

                                <div class="spacer s10"></div>
                                <div class="buttons" v-if="formComplete">
                                    <span v-if="!trialActive && !trialRequested" class="button is-primary" @click="requestDemoActivation()"
                                        ><font-awesome-icon icon="plus" /> &nbsp;Request a Free Trial</span
                                    >
                                    <span v-if="!demoBooked" class="button is-skip" @click="showDemoBooking = true"><font-awesome-icon icon="laptop" /> &nbsp;Book an Online Demo</span>
                                </div>
                                <div class="spacer s10"></div>
                                <div class="buttons" v-if="trialActive">
                                    <a href="https://dash.offshoresms.com.au" target="_blank" class="button is-info">Go to your Demo Fleet</a>
                                </div>
                            </div>
                        </div>

                        <div class="box" @click="toggle('five')" :class="{ visible: toggled.five }">
                            <div class="icon-label">
                                <div class="icon-img">
                                    <img src="@/assets/icons/trophy.jpg" />
                                </div>
                                <div>5. Subscription paid and onboarding plan underway</div>
                            </div>
                            <span class="status" :class="{ done: invoiced }"><font-awesome-icon :icon="invoiced ? 'check-square' : 'square'"/></span>
                            <div class="sub-content">
                                <div v-if="!invoiced">
                                    <p>The cost of an annual subscription to OffshoreSMS is <strong>$249</strong> per vessel or marine asset (inc GST) per year.</p>
                                    <p class="blue">
                                        Did you know that we offer a complete onboarding service that can take care of the whole setup process for you? This could include building new digital safety
                                        management systems for your marine assets, daily checklists, digital forms and maintenance plans.
                                    </p>
                                    <div class="spacer s10"></div>
                                    <div class="buttons">
                                        <span class="button is-primary" @click="showPricing = true">View our Pricing Guide</span>
                                    </div>
                                </div>
                                <div v-else>
                                    <p class="blue">You are ready to roll! Thanks for your interest in OffshoreSMS and we can't wait to support you on the next stage of your journey with us.</p>
                                    <div class="spacer s10"></div>
                                    <div class="buttons">
                                        <a href="https://dash.offshoresms.com.au" target="_blank" class="button is-info">Go to your Fleet</a>
                                    </div>
                                </div>
                                <div class="spacer s10"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="spacer s30"></div>
                    {{ formMessage }}
                </div>
            </div>
            <div v-else>
                <div class="spacer s30"></div>
                {{ errorMessage }}
            </div>

            <simple-modal title="OffshoreSMS Pricing Guide" :showComplete="priceChanged" completeText="Save Estimate" @submit="savePricing()" theme="is-primary" @closed="close()" v-if="showPricing">
                <table class="table clean pricing">
                    <tr>
                        <th>Subscription</th>
                        <td><p class="has-text-right money">$249 / asset / year</p></td>
                    </tr>
                    <tr>
                        <th>Enter number of vessels / major assets</th>
                        <td>
                            <!-- <input type="tel" placeholder="1" maxlength="2" v-model="estimate.count" class="input padded" v-on:keypress="isNumber($event)" /> -->
                            <div class="mobile-counter">
                                {{ estimate.count }}
                            </div>
                            <div class="spread three">
                                <div
                                    class="button is-danger"
                                    @click="
                                        decrement();
                                        priceChanged = true;
                                    "
                                >
                                    <font-awesome-icon icon="minus" />
                                </div>
                                <div class="counter">
                                    {{ estimate.count }}
                                </div>
                                <div
                                    class="button is-success"
                                    @click="
                                        estimate.count++;
                                        priceChanged = true;
                                    "
                                >
                                    <font-awesome-icon icon="plus" />
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>Annual Subscription</th>
                        <td>
                            <p class="has-text-right money green">${{ annualSubs }}</p>
                        </td>
                    </tr>
                    <tr>
                        <th>Basic system setup</th>
                        <td>
                            <div class="spread">
                                <div>
                                    <input class="is-checkradio is-medium" type="checkbox" id="basicSetup" v-model="estimate.basicSetup" @input="priceChanged = true" />
                                    <label for="basicSetup"></label>
                                </div>
                                <div>
                                    <span class="money" v-if="estimate.basicSetup">${{ basicSetupCharge }}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>Build new fleet SMS and apply to boats</th>
                        <td>
                            <div class="spread">
                                <div>
                                    <input class="is-checkradio is-medium" type="checkbox" id="smsSetup" v-model="estimate.smsSetup" @input="priceChanged = true" />
                                    <label for="smsSetup"></label>
                                </div>
                                <div>
                                    <span class="money" v-if="estimate.smsSetup">${{ smsSetupCharge }}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>Also copy across existing SMS content as required</th>
                        <td>
                            <div class="spread">
                                <div>
                                    <input class="is-checkradio is-medium" type="checkbox" id="copyContent" v-model="estimate.copyContent" @input="priceChanged = true" />
                                    <label for="copyContent"></label>
                                </div>
                                <div>
                                    <span class="money" v-if="estimate.copyContent">${{ copyContentCharge }}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>Additional Online Team Training Session (1.5 hours)</th>
                        <td>
                            <div class="spread">
                                <div>
                                    <input class="is-checkradio is-medium" type="checkbox" id="teamTraining" v-model="estimate.teamTraining" @input="priceChanged = true" />
                                    <label for="teamTraining"></label>
                                </div>
                                <div>
                                    <span class="money" v-if="estimate.teamTraining">${{ extraTrainingCharge }}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>Enterprise Level Fleet features</th>
                        <td>
                            <div class="spread">
                                <div>
                                    <input class="is-checkradio is-medium" type="checkbox" id="enterprise" v-model="estimate.enterprise" @input="priceChanged = true" />
                                    <label for="enterprise"></label>
                                </div>
                                <div>
                                    <span class="money" v-if="estimate.enterprise">${{ enterpriseCharge }}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>Savings</th>
                        <td>
                            <p class="has-text-right money">${{ savings }}</p>
                        </td>
                    </tr>
                    <tr>
                        <th>Total Due Now</th>
                        <td>
                            <p class="has-text-right money total">${{ totalSubs }}</p>
                        </td>
                    </tr>
                </table>
            </simple-modal>

            <simple-modal title="Book a Free Online Demo" theme="is-skip" @closed="close()" v-if="showDemoBooking">
                <div class="date-wrapper">
                    <div v-if="!dateBooked">
                        <div class="spacer s10"></div>
                        <label class="label green">A picture tells a thousand words</label>
                        <p class="blue">
                            Let one of our team members give you an interactive look at the fleet dashboard and the mobile app, all in a Google Meet or Teams online session. This really is a great way
                            to see what OffshoreSMS has to offer.
                        </p>
                        <div class="spacer s20"></div>
                        <label class="label green">What is your Preferred Demo Date?</label>
                        <p class="blue">Select a date that works for you and we will be in touch with a time that works for us both.</p>
                        <div class="spacer s20"></div>
                        <datetime placeholder="Preferred Date" auto v-model="demoDateString"></datetime>
                        <div class="spacer s20"></div>
                        <div class="buttons">
                            <span class="button is-primary is-medium" @click="confirmBooking()"><font-awesome-icon icon="calendar-check" /> &nbsp;Book this Date</span>
                        </div>
                    </div>
                    <div v-else>
                        <label class="label green">We have a date!</label>
                        <p class="blue">
                            One of our team members will be in touch very soon to confirm this date and a time that works best.
                        </p>
                    </div>
                </div>
            </simple-modal>

            <simple-modal title="Free Trial Requested" theme="is-primary" @closed="close()" v-if="showTrialConfirmation">
                Thank you. We will begin setting up your free fleet trial and will be in touch shortly.
            </simple-modal>

            <simple-modal title="Our conversation" theme="is-primary" @closed="close()" v-if="showConversation">
                <div v-if="loadingMessages"><font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp; Loading messages...</div>
                <div v-if="!loadingMessages">
                    <div v-if="messages.length > 0">
                        <div v-for="msg in messages" :key="`msg-${msg.id}`" class="message-wrapper" :class="msg.type">
                            <div class="notification">
                                <div class="heading" :class="msg.type">{{ msg.type == 'incoming' ? 'YOU' : 'OFFSHORESMS' }}: {{ simpleDate(msg.timestamp.toDate()) }}</div>
                                <div class="message">{{ msg.message }}</div>
                                <div v-if="msg.allMedia && msg.allMedia.length > 0" class="thumbnails">
                                    <div v-for="(img, imx) in msg.allMedia" class="thumb" :key="`msg-img-${imx}`">
                                        <img :src="img.url" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="spacer s10"></div>
                        <div class="form-section" v-if="addMessage">
                            <label class="label green">New message to OffshoreSMS Team</label>
                            <textarea class="textarea padded" type="text" placeholder="Type your message.." v-model="newMessage" maxlength="200"></textarea>
                        </div>
                        <div class="spacer s10"></div>
                        <div class="buttons">
                            <button v-if="!addMessage" class="button is-skip" @click="addMessage = true"><font-awesome-icon icon="comments" /> &nbsp; Add a new message</button>
                            <button v-if="addMessage" class="button is-dark" @click="addMessage = false"><font-awesome-icon icon="times" /> &nbsp; Cancel</button>
                            <button v-if="addMessage" :disabled="newMessage == '' || newMessage.trim().length < 5" class="button is-success" @click="sendMessage()">
                                <font-awesome-icon icon="check" /> &nbsp; Send Message
                            </button>
                        </div>
                        <div class="spacer s20"></div>
                    </div>
                    <div v-else>
                        <p>No messages sent or received yet.</p>
                    </div>
                </div>
                <div class="spacer s10"></div>
            </simple-modal>

            <div class="buttons floating">
                <button v-if="formUpdated" class="button is-warning" @click="updateForm()"><font-awesome-icon icon="check" /> &nbsp; Save</button>
                <button class="button is-medium is-primary" @click="showConversation = true"><font-awesome-icon icon="comments" /> &nbsp; Conversation</button>
            </div>

            <div class="spacer s100"></div>
            <div class="spacer s100"></div>
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import { collection, query, where, doc, onSnapshot, updateDoc, addDoc, getDoc, orderBy, limit } from 'firebase/firestore';
import NProgress from 'nprogress';
import { getFunctions, httpsCallable } from 'firebase/functions';
const SimpleModal = () => import('../reusables/SimpleModal.vue');

const functions = getFunctions();
const cloudFunctionSendMultipleEmails = httpsCallable(functions, 'cloudFunctionSendMultipleEmails');

export default {
    props: {
        sentCustomLog: Object,
        webLink: String
    },

    mounted: function() {
        let hasParam = this.$route.query && this.$route.query.x;
        if (hasParam) {
            this.xID = this.$route.query.x ? this.$route.query.x : '';
        } else {
            this.xID = this.webLink ? this.webLink : '';
        }
        let self = this;
        if (this.xID != '') {
            this.checkCodeExists();
        } else {
            setTimeout(() => {
                self.checkingDetails = false;
                self.status = 'No conversation found.';
                self.errorMessage = 'No conversation found.';
            }, 1000);
        }
        this.getIPFromAmazon();
    },

    components: {
        SimpleModal
    },

    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        isLoggedIn() {
            return this.user && this.user.uid && this.user.uid != '';
        },
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        },
        userID() {
            return this.userData.userid;
        },
        formComplete() {
            return this.fleetInfo.contactName != '' && this.fleetInfo.fleetName != '' && this.fleetInfo.assets != '' && this.fleetInfo.people != '' && this.fleetInfo.location != '';
        },
        annualSubs() {
            return this.estimate.count * 249;
        },
        savings() {
            return Math.floor(this.estimate.count / 5) * 249;
        },
        basicSetupCharge() {
            return this.estimate.basicSetup ? 400 + Math.floor(this.estimate.count / 10) * 200 : 0;
        },
        smsSetupCharge() {
            return this.estimate.count == 0 ? 0 : this.estimate.smsSetup ? 800 + Math.floor(this.estimate.count / 4) * 200 : 0;
        },
        copyContentCharge() {
            return this.estimate.count == 0 ? 0 : this.estimate.copyContent ? 500 + Math.floor(this.estimate.count / 4) * 200 : 0;
        },
        extraTrainingCharge() {
            return this.estimate.teamTraining ? 200 : 0;
        },
        enterpriseCharge() {
            return this.estimate.enterprise ? 1000 + Math.floor(this.estimate.count / 10) * 500 : 0;
        },
        totalSubs() {
            return this.annualSubs + this.basicSetupCharge + this.smsSetupCharge + this.copyContentCharge + this.extraTrainingCharge + this.enterpriseCharge - this.savings;
        }
    },

    data: function() {
        return {
            code: '',
            currentWebLink: '',
            checkingDetails: true,
            formExists: false,
            loadingPageData: false,
            ipAddress: '',
            hasData: false,
            status: 'Checking if conversation exists..',
            formMessage: '',
            formLoaded: false,
            errorMessage: '',

            pageMeta: {},
            formData: {},

            xID: '',
            accessAllowed: false,
            requiresLogin: false,

            userHasAccess: false,
            phoneNumber: '',
            toggled: {
                one: false,
                two: false,
                three: false,
                four: false,
                five: false,
                six: false
            },
            fleetInfo: {
                contactName: '',
                email: '',
                fleetName: '',
                notes: '',
                location: '',
                assets: '',
                people: '',
                industry: '',
                features: [],
                formComplete: false
            },
            trialActive: false,
            trialRequested: false,
            demoBooked: false,
            showDemoBooking: false,
            demoDate: new Date(),
            demoDateString: new Date().toISOString(),
            showTrialConfirmation: false,
            invoiced: false,
            showPricing: false,
            showConversation: false,
            messages: [],
            loadingMessages: false,
            addMessage: false,
            newMessage: '',
            estimate: {
                count: 1,
                total: 249,
                basicSetup: false,
                smsSetup: false,
                copyContent: false,
                teamTraining: false,
                enterprise: false
            },
            priceChanged: false,
            assetOptions: ['1 - 3', '4 - 6', '7 - 10', '11 - 15', '16 - 20', '20 - 30', '30 - 50', 'More than 50'],
            peopleOptions: ['1 - 5', '2 - 10', '11 - 20', 'More than 20'],
            features: ['Planned maintenance', 'Safety Management Systems', 'Crew Management', 'Custom Logs & Forms', 'Drills & Training', 'Fleet Management Tools', 'Reporting', 'Other'],
            formUpdated: false,
            dateBooked: false
        };
    },

    methods: {
        close() {
            this.showTimelineModal = false;
            this.newPin = '';
            this.validating = false;
            this.savingEntry = false;
            this.errorMsg = '';
            this.showPricing = false;
            this.showConversation = false;
            this.loadingMessages = false;
            // this.messages = [];
            this.addMessage = false;
            this.newMessage = '';
            this.showDemoBooking = false;
            this.showTrialConfirmation = false;
            this.priceChanged = false;
            this.dateBooked = false;
        },

        toggle(num) {
            this.toggled = {
                one: false,
                two: false,
                three: false,
                four: false,
                five: false,
                six: false
            };
            this.toggled[num] = true;
        },

        decrement() {
            if (this.estimate.count > 0) this.estimate.count--;
        },

        simpleDate(date) {
            let dateOptions = { timeZone: 'Australia/Sydney', month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };
            return date == '' ? '' : new Date(date).toLocaleDateString('en-US', dateOptions);
        },

        async confirmBooking() {
            let self = this;
            NProgress.start();
            const updateRef = doc(db, 'smsConversations/' + this.formData.id);
            await updateDoc(updateRef, {
                demoBooked: true,
                demoDate: new Date(self.demoDateString)
            });
            setTimeout(() => {
                this.dateBooked = true;
                NProgress.done();
            }, 500);
        },

        async updateForm() {
            NProgress.start();
            const updateRef = doc(db, 'smsConversations/' + this.formData.id);
            // let updateData = { ...this.fleetInfo };
            // updateData.formComplete = this.formComplete;
            await updateDoc(updateRef, this.fleetInfo);
            this.formUpdated = false;
            NProgress.done();
        },

        async savePricing() {
            let self = this;
            NProgress.start();
            const updateRef = doc(db, 'smsConversations/' + this.formData.id);
            await updateDoc(updateRef, {
                estimate: self.estimate
            });
            this.close();
            NProgress.done();
        },

        async getIPFromAmazon() {
            fetch('https://checkip.amazonaws.com/')
                .then(res => res.text())
                .then(data => {
                    // console.log('AWS', data);
                    this.ipAddress = data;
                });
        },

        isNumber: function($evt) {
            $evt = $evt ? $evt : window.event;
            var charCode = $evt.which ? $evt.which : $evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                $evt.preventDefault();
            } else {
                return true;
            }
        },

        async checkCodeExists() {
            this.errorMessage = '';
            let self = this;
            // console.log('checking', this.xID);

            const docRef = doc(db, 'smsEnquiryUsedCodes', this.xID);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                self.pageMeta = docSnap.data();
                // if (docSnap.data().phoneNumber) {
                //     self.$store.dispatch('setCurrentConversation', { vesselID });
                // }
                // let logMeta = self.pageMeta;
                // self.$store.dispatch('setCurrentConversationMeta', { logMeta });
                setTimeout(() => {
                    self.status = 'Checking status...';
                    self.formExists = true;
                    self.loadingPageData = true;
                    self.checkingDetails = false;
                    self.loadFormData();
                }, 500);
            } else {
                self.checkingDetails = false;
                self.status = 'This page could not be loaded.';
                self.errorMessage = 'No conversation found.';
            }
        },

        loadFormData() {
            let self = this;
            if (this.pageMeta.phoneNumber && this.pageMeta.phoneNumber != '') {
                self.hasData = true;
                self.status = 'Loading new information...';
                setTimeout(() => {
                    self.loadConversationData();
                }, 500);
            }
        },

        async loadConversationData() {
            let self = this;

            const unsub = onSnapshot(doc(db, 'smsConversations', this.pageMeta.phoneNumber), doc => {
                if (doc.exists()) {
                    let data = doc.data();
                    data.id = doc.id;
                    self.currentWebLink = doc.data().webLink ? doc.data().webLink : '';
                    if (self.xID != self.currentWebLink) {
                        self.loadingPageData = false;
                        self.errorMessage = 'Page expired. Access denied.';
                        return;
                    }
                    self.formData = data;

                    self.fleetInfo.contactName = data.contactName ? data.contactName : '';
                    self.fleetInfo.email = data.email ? data.email : '';
                    self.fleetInfo.fleetName = data.fleetName ? data.fleetName : '';
                    self.fleetInfo.notes = data.notes ? data.notes : '';
                    self.fleetInfo.location = data.location ? data.location : '';
                    self.fleetInfo.assets = data.assets ? data.assets : '';
                    self.fleetInfo.people = data.people ? data.people : '';
                    self.fleetInfo.industry = data.industry ? data.industry : '';
                    self.fleetInfo.features = data.features ? data.features : [];

                    self.trialActive = data.trialActive ? data.trialActive : false;
                    self.trialRequested = data.trialRequested ? data.trialRequested : false;
                    self.demoBooked = data.demoBooked ? data.demoBooked : false;
                    self.demoDate = data.demoDate ? data.demoDate : new Date();
                    self.demoDateString = data.demoDate ? data.demoDate.toDate().toISOString() : new Date().toISOString();

                    self.invoiced = data.invoiced ? data.invoiced : false;
                    self.estimate = data.estimate
                        ? data.estimate
                        : {
                              count: 1,
                              total: 249,
                              basicSetup: false,
                              smsSetup: false,
                              copyContent: false,
                              teamTraining: false,
                              enterprise: false
                          };
                    // let logData = self.formData;
                    // self.$store.dispatch('setCurrentConversationData', { logData });
                    setTimeout(() => {
                        self.accessAllowed = true;
                        self.loadingPageData = false;
                        self.formLoaded = true;
                        self.loadMessages();
                    }, 500);
                } else {
                    self.formMessage = 'This web page is not available, sorry.';
                    self.loadingPageData = false;
                }
            });
        },

        loadMessages() {
            let self = this;
            this.loadingMessages = true;

            const collectionRef = collection(db, 'smsConversations', this.pageMeta.phoneNumber, 'messages');
            const q = query(collectionRef, orderBy('timestamp', 'asc'));
            const msgsUnsubscribe = onSnapshot(q, querySnapshot => {
                this.messages = [];
                querySnapshot.forEach(doc => {
                    let data = doc.data();
                    data.id = doc.id;
                    self.messages.push(data);
                });
            });
            setTimeout(() => {
                this.loadingMessages = false;
            }, 500);
        },

        async sendMessage() {
            NProgress.start();
            let self = this;
            let timestamp = new Date();

            const docRef = await addDoc(collection(db, 'smsConversations', this.pageMeta.phoneNumber, 'messages'), {
                message: self.newMessage,
                sender: self.formData.contactName,
                type: 'incoming',
                timestamp: timestamp
            });

            await updateDoc(doc(db, 'smsConversations', this.pageMeta.phoneNumber), {
                lastMessageRead: false,
                lastMessageID: docRef.id,
                lastMessageDate: timestamp
            });

            this.addMessage = false;
            this.newMessage = '';
            NProgress.done();
        },

        async requestDemoActivation() {
            // window.alert('Set up my demo fleet please..');
            NProgress.start();
            const updateRef = doc(db, 'smsConversations/' + this.formData.id);
            await updateDoc(updateRef, {
                trialRequested: true
            });
            setTimeout(() => {
                this.showTrialConfirmation = true;
                NProgress.done();
            }, 500);
        },

        validateAccess() {
            let self = this;
            self.accessAllowed = true;
            self.loadingPageData = false;
            self.formLoaded = true;
        },

        checkUserAccess() {
            let self = this;
            this.userHasAccess = this.isLoggedIn && this.allVesselCrewIDs.includes(this.userID);
            this.formMessage = this.userHasAccess ? 'Access permitted..' : 'This form is only available to authorised users.';
            self.loadingPageData = false;
            self.formLoaded = true;
        },

        showLogin() {
            this.$router.push({
                name: 'UserLogin',
                params: {
                    return: true
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
h2 {
    color: cadetblue;
    font-size: 1.2rem;
    font-weight: bold;
}
.intro {
    color: cadetblue;
    p {
        a {
            color: dodgerblue;
            &:visited {
                color: dodgerblue;
            }
        }
    }
}

.box {
    position: relative;
    padding-right: 40px;
    cursor: pointer;
    .icon-label {
        display: flex;
        margin-bottom: 8px;
        line-height: 1.2rem;
        color: cadetblue;
        text-transform: uppercase;
        font-weight: bold;
        align-items: center;
        .icon-img {
            margin-right: 6px;
            width: 30px;
            display: flex;
            align-items: center;
            transition: 0.25s;
            img {
                width: 100%;
            }
        }
        .label-text {
            flex-grow: 1;
        }
    }
    .status {
        position: absolute;
        top: 7px;
        right: 18px;
        font-size: 2rem;
        color: #ccc;
        &.done {
            color: mediumseagreen;
        }
    }
    &:hover {
        .icon-label {
            .icon-img {
                transform: scale(1.2);
            }
        }
    }
    .sub-content {
        display: none;
        transition: 0.25s;
        p {
            margin-bottom: 6px;
        }
    }
    &.visible {
        .sub-content {
            display: block;
        }
        .icon-label {
            margin-bottom: 1rem;
        }
    }
    .form-section {
        margin-bottom: 1.5rem;
    }
}
.message-wrapper {
    margin-bottom: 1rem;
    display: flex;
    .notification {
        width: 90%;
    }
    &.incoming {
        justify-content: flex-end;
        text-align: right;
        .thumbnails {
            justify-content: flex-end;
            .thumb {
                margin: 0 0 12px 12px;
            }
        }
    }
    &.outgoing {
        justify-content: flex-start;
    }
    .msg-type {
        font-size: 0.7rem;
        margin-bottom: 3px;
        text-transform: uppercase;
    }
    .heading {
        &.incoming {
            color: #444;
        }
        &.outgoing {
            color: green;
        }
    }
    .message {
        color: dodgerblue;
        font-size: 1rem;
    }
}
table {
    &.pricing {
        tr {
            th {
                vertical-align: middle;
                padding: 12px;
            }
            td {
                vertical-align: middle;
                padding: 12px;
                .mobile-counter {
                    font-size: 1.8rem;
                    line-height: 2rem;
                    color: dodgerblue;
                    font-weight: bold;
                    display: none;
                }
                .spread {
                    display: flex;
                    justify-content: space-between;
                    &.three {
                        justify-content: space-evenly;
                        align-items: center;
                    }
                    .counter {
                        font-size: 1.8rem;
                        // line-height: 2rem;
                        color: dodgerblue;
                        font-weight: bold;
                    }
                }
                .money {
                    font-size: 1rem;
                    color: dodgerblue;
                    font-weight: bold;
                    &.green {
                        color: mediumseagreen;
                    }
                    &.total {
                        color: mediumseagreen;
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }
}
.date-wrapper {
    min-height: 480px;
}

@media only screen and (max-width: 600px) {
    .box {
        .status {
            top: 0px;
            right: 10px;
            font-size: 1.6rem;
        }
    }
    .table {
        &.pricing {
            tr {
                td {
                    .mobile-counter {
                        display: block;
                    }
                }
            }
        }
    }
    .counter {
        display: none;
    }
}
</style>
