<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <ul class="scroller">
                    <li><router-link to="/">Home</router-link></li>
                    <li class="is-active"><a aria-current="page">Book a Demo</a></li>
                </ul>

                <div v-if="!formSent" class="content">
                    <div class="spacer s30"></div>

                    <div class="hero-wrapper">
                        <!-- <img src="@/assets/preso-hero2.jpg" alt=""> -->
                        <ImageItem class="article-item__image" :source="require(`../assets/preso-hero2.jpg`)" alt="Online Demo" />
                    </div>

                    <h3 class="centered">Book a free online Demo and see what OffshoreSMS has to offer your business.</h3>

                    <div class="spacer s20"></div>

                    <h5 class="">
                        Please tell us a bit more about your marine operation and the people and vessels in it. This will help us to share more meaningful content with you when we connect.
                    </h5>

                    <hr />

                    <div class="form-group-new">
                        <label :class="{ required: demo.person == '' }" for="">Your name:</label>
                        <input class="input padded" type="text" placeholder="Type your name.." v-model="demo.person" />
                    </div>

                    <div class="form-group-new">
                        <label :class="{ required: demo.email == '' }" for="">Email address:</label>
                        <input class="input padded" type="text" placeholder="Your email address.." v-model="demo.email" />
                    </div>

                    <div class="form-group-new">
                        <label :class="{ required: demo.phone == '' }" for="">Phone number:</label>
                        <input class="input padded" type="text" placeholder="Your phone number.." v-model="demo.phone" />
                    </div>

                    <div class="form-group-new">
                        <label for="">Business name:</label>
                        <input class="input padded" type="text" placeholder="Type your name.." v-model="demo.business" />
                    </div>

                    <div class="form-group-new">
                        <label for="">Where are you based?</label>
                        <input class="input padded" type="text" placeholder="Type your town or city.." v-model="demo.location" />
                    </div>

                    <div class="form-group-new">
                        <label for="">How many vessels in your operation?</label>
                        <input class="input padded" type="tel" maxlength="3" placeholder="Enter number of vessels.." v-on:keypress="isNumber($event)" v-model="demo.numVessels" />
                    </div>

                    <div class="form-group-new">
                        <label for="">How many skippers and crew?</label>
                        <input class="input padded" type="tel" maxlength="3" placeholder="Enter number of crew.." v-on:keypress="isNumber($event)" v-model="demo.numCrew" />
                    </div>

                    <div class="form-group-new">
                        <label for="">Any particular fleet management, safety or compliance areas you're looking to improve?</label>
                        <textarea class="textarea padded" placeholder="Enter any comments here.." v-model="demo.comments"></textarea>
                    </div>

                    <div class="form-group-new">
                        <label>Your Preferred Demo Date:</label>
                        <datetime placeholder="Preferred Date" auto v-model="demo.date"></datetime>
                    </div>

                    <hr />

                    <div class="buttons">
                        <button :disabled="buttonDisabled" class="button is-medium is-primary" @click="submitForm()">Submit Demo Booking Enquiry</button>
                    </div>
                </div>

                <div v-else class="content centered">
                    <div class="spacer s20"></div>
                    <h3>Thank you for your demo enquiry.<br />One of our team members will be in touch with you very shortly.</h3>
                    <div class="spacer s20"></div>
                    <h5>
                        In the meantime, please feel free to create a free user profile from the link below. This will allow us to set up a demo vessel so you can access some of the great features of
                        OffshoreSMS.
                    </h5>

                    <div class="spacer s20"></div>
                    <div class="buttons centered">
                        <span class="button is-medium is-primary" @click="trackLink('sign-up', 'signup')">Create my FREE user profile</span>
                    </div>
                    <div class="spacer s10"></div>

                    <p>
                        You might also like to download the OffshoreSMS app from your app store.<br />The OffshoreSMS app is the ultimate tool for digital vessel logs, as well as crew, scheduled
                        maintenance, workflows and safety management systems for any commercial vessel.
                    </p>
                </div>

                <div class="spacer s100"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import ImageItem from '../reusables/ImageItem.vue';

export default {
    mounted() {
        let now = new Date();
        // this.demo.date = new Date(now.setDate(now.getDate() + 3)).toISOString();
        this.demo.date = new Date().toISOString();
    },
    components: {
        ImageItem
    },
    computed: {
        // user() {
        //     return this.$store.getters.getUser;
        // },
        buttonDisabled() {
            return this.demo.person == '' || this.demo.email == '' || this.demo.phone == '';
        }
    },
    data: function() {
        return {
            formSent: false,
            demo: {
                person: '',
                email: '',
                phone: '',
                business: '',
                location: '',
                numVessels: 0,
                numCrew: 0,
                date: '',
                comments: ''
            }
        };
    },
    methods: {
        isNumber: function($evt) {
            $evt = $evt ? $evt : window.event;
            var charCode = $evt.which ? $evt.which : $evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                $evt.preventDefault();
            } else {
                return true;
            }
        },

        submitForm() {
            console.log(this.demo);
            NProgress.start();

            let self = this;

            let dateOptions = { timeZone: 'Australia/Sydney', month: 'short', day: 'numeric', year: 'numeric' };
            let simpleDate = new Date(this.demo.date).toLocaleDateString('en-US', dateOptions);

            let saveData = {
                contactPerson: self.demo.person,
                contactEmail: self.demo.email,
                contactPhone: self.demo.phone,
                businessName: self.demo.business,
                location: self.demo.location,
                numVessels: self.demo.numVessels,
                numCrew: self.demo.numCrew,
                comments: self.demo.comments,
                bookingDate: new Date(self.demo.date),
                timestamp: new Date()
            };
            db.collection('demoBookings').add(saveData);

            var generalEmail = firebase.functions().httpsCallable('generalEmail');
            generalEmail({
                subject: 'New demo booking enquiry',
                message: self.demo.person + ' has requested a demo booking on ' + simpleDate + ' (' + self.demo.email + ')'
            });

            var sendTwilioSMS = firebase.functions().httpsCallable('sendTwilioSMS');
            sendTwilioSMS({
                toNumber: '+61409709596',
                message: self.demo.person + ' has requested a demo booking on ' + simpleDate,
                userName: self.demo.person,
                userID: '',
                revisionDescription: self.demo.person + ' has requested a demo booking on ' + simpleDate
            });

            setTimeout(() => {
                this.formSent = true;
                NProgress.done();
            }, 1500);
        },

        trackLink(page, type) {
            console.log(page, type);
            NProgress.start();
            let addRevision = firebase.functions().httpsCallable('addRevision');
            addRevision({
                userID: '-',
                userName: '-',
                description: 'A user has clicked through to the dash.offshoresms register page.'
            });
            // this.$router.replace(page);
            // window.location.href = 'https://dash.offshoresms.com.au/' + page;
            window.location.href = 'https://dash.offshoresms.com.au';
            NProgress.done();
        }
    }
};
</script>

<style lang="scss" scoped>
h3 {
    color: deepskyblue;
}
h5 {
    color: #555;
}
img {
    max-width: 100%;
}
</style>
