<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container full-width">
                <div class="spacer s30"></div>

                <div class="hero has-text-centered">
                    <div class="spacer s40"></div>
                    <p class="title is-3 is-dark">Fleet Management Dashboard</p>
                    <p class="subtitle is-5">
                        A central, real-time dashboard for owners and managers of multi-vessel fleets.
                    </p>
                    <p class="subtitle is-5 bold red">FREE for a limited time!</p>
                    <div class="spacer s10"></div>
                </div>

                <div class="pricing-wrapper">
                    <div class="pricing-table">
                        <div class="pricing-plan is-dark">
                            <div class="plan-header is-dark">Free for a Limited Time!</div>
                            <div class="plan-price">
                                <span class="plan-price-amount"><span class="plan-price-currency">$</span><span class="strikethrough">299</span></span>
                                <span class="price-rate">per fleet<br />per year</span>
                            </div>
                            <div class="plan-items">
                                <div class="plan-item">Allow skippers to do all<br />logs on your behalf</div>
                                <div class="plan-item">Create unlimited custom<br />logs and forms</div>
                                <div class="plan-item">Scheduled maintenance and<br />vessel drills</div>
                                <div class="plan-item">Includes passenger tools<br />& timed activities</div>
                                <div class="plan-item">Perfect for fleet owners<br />and busy fleet managers</div>
                            </div>
                            <div class="plan-footer">
                                <a href="#features" v-smooth-scroll="{ offset: -100 }" class="button is-fullwidth">See all Features</a>
                            </div>
                        </div>
                    </div>

                    <div class="other-plans">
                        <img src="@/assets/img/fleet-screens.png" />
                    </div>

                    <div class="clear"></div>
                </div>

                <div v-if="userData.userid" class="buttons centered">
                    <span class="button is-medium is-light" @click="trackLink('my-dashboard', 'dashboard')">View Your Dashboard</span>
                </div>
                <div v-else class="buttons centered">
                    <span class="button is-medium is-success" @click="trackLink('sign-up', 'signup')">Get Started for FREE</span>
                    <span class="button is-medium is-light" @click="trackLink('sign-in', 'login')">Member Log in</span>
                </div>
                <div class="spacer s20"></div>

                <div class="like-wrapper centered">
                    <span v-if="!liked" class="button is-medium is-pink" @click="like()"
                        ><font-awesome-icon v-if="liking" icon="cog" class="fa-spin" /><font-awesome-icon v-else icon="thumbs-up" />&nbsp;Like this</span
                    >
                    <span v-else class="button is-medium is-light"><font-awesome-icon icon="thumbs-up" />&nbsp;Thanks!</span>
                    <span class="like-counter">{{ likes }}</span>
                </div>

                <div class="spacer s20"></div>

                <div id="features" class="feature-list">
                    <div class="main-content">
                        <div class="notification centered">
                            <h2>Fleet Dashboard Features:</h2>
                        </div>

                        <div class="side-icon left"><img src="@/assets/icons/rocket.jpg" /></div>
                        <div class="side-text right">
                            <h5>
                                Introducing our amazing Fleet Dashboard - Perfect for multi-vessel fleets!
                            </h5>
                            <p>
                                The PRO plan includes all digital logs and the full digital Ssafety Management System features. What makes the PRO plan hard to beat is the range of powerful add-ons,
                                including timed activities, scheduled drills and maintenance, shutdown logs, JSA checklists, mid-trip activity checklists, a vessel defect register plus some pretty
                                cool passenger tools (read more below).
                            </p>
                        </div>
                        <div class="clear"></div>

                        <hr />
                        <div class="side-icon right"><img src="@/assets/icons/sound.jpg" /></div>
                        <div class="side-text left">
                            <h5>Real-time team messaging with read receipts</h5>
                            <p>
                                We've just launched a new team messaging feature for fleets. Simply choose which members of your team you'd like to message (ie all crew and skippers on any of your
                                vessels), type your message and any optional pre-set responses, and send to one or many!
                            </p>
                            <p>
                                Any of your team members with the OffshoreSMS app installed on their mobile phone will get an instant message in the app, and will be able to reply with a quick
                                response. Best of all, you get to see exactly who read the message and when!
                            </p>
                        </div>
                        <div class="clear"></div>
                        <hr />

                        <div class="side-icon left"><img src="@/assets/icons/devices.jpg" /></div>
                        <div class="side-text right">
                            <h5>View all activity on all vessels in real time</h5>
                            <p>
                                The live activity feed on the Fleet Dashboard lets you see every single interaction, log, induction and more in real time (yes as it happens), and in one central place.
                            </p>
                            <p>
                                The more vessels and crew/skippers that you have connected, the more activity and better record of compliance your fleet will have.
                            </p>
                        </div>
                        <div class="clear"></div>

                        <hr />
                        <div class="side-icon right">
                            <img src="@/assets/icons/maintenance2.jpg" />
                        </div>
                        <div class="side-text left">
                            <h5>
                                View all defects, expired equipment and active schedules across the fleet
                            </h5>
                            <p>
                                The fleet dashboard is the perfect place to monitor all defects across your entire fleet, all in real-time (whenever a defect is added to a vessel). Also track expired
                                safety equipment and up-coming important dates, drills and schedules across the whole fleet, all in one place.
                            </p>
                        </div>
                        <div class="clear"></div>
                        <hr />

                        <div class="side-icon left"><img src="@/assets/icons/team.jpg" /></div>
                        <div class="side-text right">
                            <h5>Keep track of all crew qualifications in your fleet</h5>
                            <p>
                                The bigger your fleet crew gets, the harder it becomes to ensure that all crew and skippers' qualifications and contact information is kept up-to-date. Having all your
                                crew in one place in the fleet dashboard means that you know exactly whose qualifications are due or expired, and whose profiles and emergency information needs to be
                                updated
                            </p>
                            <p>
                                Remember that you can add an unlimited number of crew to any vessel, at no cost. All you need to do is get your crew and skippers to download the app and create a free
                                user profile. No cost to them or you!
                            </p>
                        </div>
                        <div class="clear"></div>

                        <hr />
                        <div class="side-icon right">
                            <img src="@/assets/icons/compliance.jpg" />
                        </div>
                        <div class="side-text left">
                            <h5>
                                Use our SMS audt tool to monitor SMS risk and compliance for all vessels in the fleet
                            </h5>
                            <p>
                                We've built a simple risk calculator that you can use to check the status of each fleet vessel's SMS, and how complete it is. Vessels can be low, medium or high risk,
                                allowing you to address SMS deficiencies quickly and easily.
                            </p>
                        </div>
                        <div class="clear"></div>
                        <hr />

                        <div class="side-icon left"><img src="@/assets/icons/target.jpg" /></div>
                        <div class="side-text right">
                            <h5>A central hub to view all vessel logs</h5>
                            <p>
                                View any log completed by any of your vessel skippers, including full trip logs, maintenance logs, all ship's logs, incident reports, and of course any of the custom
                                logs that you have created for each vessel.
                            </p>
                            <p>
                                Need more detail? Simply create a report from the interactive reporting tool (see below).
                            </p>
                        </div>
                        <div class="clear"></div>

                        <hr />
                        <div class="side-icon right">
                            <img src="@/assets/icons/checklist.jpg" />
                        </div>
                        <div class="side-text left">
                            <h5>
                                View and print any type of report, from ship's logs to timed activities!
                            </h5>
                            <p>
                                You can print out a live, up-to-date report on any number of activities from any vessel in the fleet, including ship's logs, all vessel activity, timed activities, trip
                                reports, maintenance logs or fuel logs.
                            </p>
                            <p>
                                Filter each report based on item or period, or drop any individual line item as needed, then print the report as a PDF.
                            </p>
                        </div>
                        <div class="clear"></div>
                        <hr />

                        <div class="side-icon left"><img src="@/assets/icons/boat-3.jpg" /></div>
                        <div class="side-text right">
                            <h5>Create Fleet level SMS content and apply it to any fleet vessel</h5>
                            <p>
                                We've made it super easy to manage similar safety management systems across vessels in your fleet. Simply create a policy, procedure or any other checklist as a
                                Fleet-level item, then add it to any vessel. This will save you a ton of time and effort writing multiple SMSs for multiple vessels.
                            </p>
                            <p>
                                Plus you can switch between vessel SMSs at the click of a button, rather than loading multiple Word documents!
                            </p>
                        </div>
                        <div class="clear"></div>

                        <hr />
                        <div class="side-icon right"><img src="@/assets/icons/crew.jpg" /></div>
                        <div class="side-text left">
                            <h5>Australian-made with 100% local support.</h5>
                            <p>
                                We are a local Australian business with active marine backgrounds. We know you're busy and we're here to make life easy for you. Our support is 100% free and unlimited,
                                24-7. Well almost 24/7.
                            </p>
                        </div>
                        <div class="clear"></div>
                        <!-- <hr> -->
                    </div>
                </div>

                <div class="spacer s20"></div>

                <div class="buttons centered">
                    <span v-if="!userExists" class="button is-medium is-success" @click="trackLink('sign-up', 'signup')">Join for FREE and decide later</span>
                    <span v-else class="button is-medium is-primary" @click="trackLink('my-dashboard', 'dashboard')">Member Log in</span>
                </div>

                <div class="spacer s20"></div>

                <hr />

                <div class="has-text-centered">
                    <!-- <hr> -->
                    <!-- <p>Did you know that we offer a 30-Day 100% Money Back Guarantee?</p> -->
                    <div class="has-text-centered">
                        <!-- <p>&nbsp;</p>
						<p>* Billed Annually.</p> -->
                        <p>&nbsp;</p>
                        <p>
                            Did you know that we offer a 30 Day 100% Money Back Guarantee if you're not completely happy?
                        </p>
                        <p>&nbsp;</p>
                        <span @click="isActiveMoreInfo = true" class="button is-info">Need more information?</span>
                        <p>&nbsp;</p>
                    </div>
                </div>

                <div class="spacer s100"></div>
            </div>
        </div>

        <div class="buttons floating">
            <button class="button is-medium is-primary" @click="openEnquiry = true"><font-awesome-icon icon="comments" /> &nbsp; Quick Enquiry</button>
        </div>

        <quick-enquiry v-if="openEnquiry" @closed="close()" />

        <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': isActiveMoreInfo }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Need more information?</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>

                <section class="modal-card-body">
                    <div>
                        <p>
                            Click here to view our
                            <router-link to="faqs">Frequently Asked Questions</router-link>.
                        </p>
                        <p>
                            Still need some more information? Please feel free to give us a call on
                            <a href="tel:+61-447-139-382">+61 447 139 382</a> and let's have a chat.
                        </p>
                    </div>
                </section>

                <footer class="modal-card-foot">
                    <!-- <button class="button is-primary" @click="checkout">Checkout</button> -->
                    <button class="button cancel" @click.prevent="close">Done</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '@/main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import QuickEnquiry from '../reusables/QuickEnquiry.vue';

export default {
    name: 'PlanFleet',
    components: {
        QuickEnquiry
    },
    data: function() {
        return {
            firebaseid: '',
            userExists: false,
            chosenPlan: '',
            chosenDescription: '',
            chosenPrice: '',
            chosenDisplayPrice: '',
            plans: {
                Free: {
                    plan: 'Free',
                    description: 'Crew plan',
                    price: 0,
                    class: 'is-warning',
                    vessels: 0
                },
                Starter: {
                    plan: 'Starter',
                    description: 'SMS Starter plan',
                    price: 149,
                    class: 'is-primary',
                    vessels: 2
                },
                Fleet: {
                    plan: 'Fleet',
                    description: 'Fleet plan',
                    price: 249,
                    class: 'is-danger',
                    vessels: 10
                }
            },
            signupModal: false,
            contactModal: false,
            enquiryCompany: '',
            enquiryContact: '',
            enquiryPhone: '',
            enquiryEmail: '',
            enquiryDetails: '',
            formSent: false,
            isActiveMoreInfo: false,

            likes: 0,
            liked: false,
            liking: false,
            openEnquiry: false
        };
    },
    computed: {
        showSendContact() {
            return (this.enquiryCompany != '') & (this.enquiryContact != '') && this.enquiryPhone != '';
        },
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        }
    },

    mounted() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
        this.getLikes();
    },

    methods: {
        onUserLogin: function(user) {
            let self = this;
            this.getLikes();
            if (user) {
                this.firebaseid = user.uid;
                this.userExists = true;
            }
        },

        getLikes() {
            let self = this;
            db.collection('pageLikes')
                .doc('fleetPlan')
                .onSnapshot(doc => {
                    self.likes = doc.data().likes;
                });
        },

        like() {
            let self = this;
            this.liking = true;
            db.collection('pageLikes')
                .doc('fleetPlan')
                .update({
                    likes: firebase.firestore.FieldValue.increment(1)
                })
                .then(() => {
                    setTimeout(() => {
                        self.liking = false;
                        self.liked = true;
                    }, 500);
                })
                .then(() => {
                    let person = self.userData.username ? self.userData.username : 'Someone';
                    let addRevision = firebase.functions().httpsCallable('addRevision');
                    addRevision({
                        userID: self.userDatauserid ? self.userData.userid : '',
                        userName: self.userData.username ? self.userData.username : '',
                        description: person + ' has liked the Fleet Plan page!'
                    });
                });
        },

        close: function() {
            this.signupModal = false;
            this.contactModal = false;
            this.enquiryCompany = '';
            this.enquiryContact = '';
            this.enquiryPhone = '';
            this.enquiryEmail = '';
            this.enquiryDetails = '';
            this.isActiveMoreInfo = false;
            this.openEnquiry = false;
        },

        trackLink(page, type) {
            console.log(page, type);
            NProgress.start();
            let addRevision = firebase.functions().httpsCallable('addRevision');
            addRevision({
                userID: '-',
                userName: '-',
                description: 'A user has clicked through to the app.offshoresms ' + type + ' page.'
            });
            // this.$router.replace(page);
            // window.location.href = 'https://dash.offshoresms.com.au/' + page;
            window.location.href = 'https://dash.offshoresms.com.au/';
            // NProgress.done();
        },

        // toSignUp() {
        //   window.fbq('track', 'Lead');
        //   // window.location.href = 'https://dash.offshoresms.com.au/sign-up';
        //   this.$router.push({ name: 'signUp', params: {
        //       // sentPlanIndex: 3,
        //       // aaaPlans: ['logs','sms','pro'],
        //     }
        //   });
        // },

        sendContactEmail: function() {
            //console.log(this.enquiryCompany,this.enquiryContact,this.enquiryPhone,this.enquiryEmail,this.enquiryDetails);
            NProgress.start();
            var self = this;
            var enquiryArray = {
                enquiryCompany: self.enquiryCompany,
                enquiryContact: self.enquiryContact,
                enquiryPhone: self.enquiryPhone,
                enquiryEmail: self.enquiryEmail,
                enquiryDetails: self.enquiryDetails
            };
            var sendEmailFleetEnquiry = firebase.functions().httpsCallable('sendEmailFleetEnquiry');
            sendEmailFleetEnquiry({
                enquiryArray: enquiryArray
            });

            this.formSent = true;
            NProgress.done();
        }
    }
};
</script>

<style lang="scss" scoped>
// .site-wrapper {
//    &.no-header {
//      background: #1d4ace;
//      background-image: linear-gradient(#1f3082, #43a4ea);
//    }
//    .container {
//      background: none;
//      .card {
//        &.transparent {
//          background: none;
//        }
//      }
//    }
//  }
textarea {
    min-height: 100px !important;
    box-shadow: none;
    padding: 0.6rem 1rem;
}

.pricing-table {
    .pricing-plan {
        padding-bottom: 1rem;
        margin-bottom: 2rem;
        .plan-header {
            padding-bottom: 0;
            &.is-dark {
                background: #363636 !important;
            }
        }
        .plan-footer {
            margin-top: 0;
            margin-bottom: 1rem;
            .button {
                margin-top: 1rem;
                height: auto;
            }
        }
        .plan-price {
            .price-rate {
                width: 70px;
                display: inline-block;
                margin-left: 5px;
                line-height: 14px;
                text-align: left;
            }
        }
    }
}

.hero {
    margin-bottom: 3rem;
    .is-primary {
        color: #00d1b2;
    }
    .is-info {
        color: #209cee;
    }
    .is-warning {
        color: #fdd32c;
    }
    .is-danger {
        color: #ff3860;
    }
    h3 {
        color: #fff;
    }
}

.feature-list {
    border: 1px solid #f1f1f1;
    border-radius: 15px;
    background: #fff;
    margin-top: 1rem;
    .icon-list {
        width: 100%;
        margin: 3rem auto;
        max-width: 600px;
        li {
            margin-bottom: 2rem;
            width: 100%;
            .small-icon {
                float: left;
                width: 80px;
                img {
                    width: 100%;
                }
            }
            .details {
                float: left;
                width: calc(100% - 120px);
                font-family: 'Roboto Slab', serif;
                // color: #ff7878;
                color: #37cda8;
                font-size: 1.4rem;
                line-height: 1.8rem;
                text-align: left;
                margin-left: 10px;
                padding-top: 1rem;
            }
        }
    }
}

.pricing-wrapper {
    width: 100%;
    // max-width: 900px;
    margin: 0 auto;
    .pricing-table {
        width: 44%;
        float: left;
        .pricing-plan {
            // border: 2px solid #ff3860;
            border-radius: 8px;
            box-shadow: rgba(122, 122, 122, 0.2) 0 8px 12px 0;
            .plan-header {
                padding-bottom: 1rem;
                color: #fff;
                background: #ff3860;
            }
            .plan-price {
                .price-rate {
                    width: 60px;
                    display: inline-block;
                    margin-left: 5px;
                    line-height: 14px;
                    text-align: left;
                }
            }
            .plan-footer {
                a {
                    padding: 1rem;
                }
            }
        }
        .heading {
            text-align: center;
            margin: 0 auto 1rem;
        }
        &.squashed {
            padding: 0;
            width: 100%;
            .pricing-plan {
                width: 100%;
                display: block;
                margin-bottom: 10px;
                border: 1px solid whitesmoke;
                .plan-header {
                    width: 42%;
                    float: left;
                    height: auto;
                    padding: 0.75em;
                    padding-top: 1.3rem;
                    background: #fff;
                    color: #333;
                }
                .plan-price {
                    width: 50%;
                    float: left;
                    text-align: left;
                }
                .plan-footer {
                    a {
                        // background: #d2cdcd;
                        padding: 5px 12px;
                    }
                }
            }
        }
    }
    .other-plans {
        width: 56%;
        float: right;
        padding: 0 2rem;
        text-align: center;
        img {
            max-width: 100%;
            margin: 2rem 0;
        }
    }
}

.main-content {
    width: 100%;
    .notification {
        margin-bottom: 3rem;
        h2 {
            font-size: 1.7rem;
            // margin-bottom: 10px;
            text-transform: uppercase;
            color: steelblue;
            font-weight: bold;
        }
    }
    h5 {
        text-transform: uppercase;
        margin-bottom: 10px;
        font-weight: bold;
        font-family: 'Roboto Slab', serif;
        color: #37cda8;
        font-size: 1.4rem;
        line-height: 1.8rem;
        text-align: left;
        padding-top: 1rem;
    }
    p {
        line-height: 1.8rem;
    }
    hr {
        margin: 2.5rem 0;
    }
    .side-icon {
        width: 25%;
        &.left {
            float: left;
            padding-right: 1rem;
        }
        &.right {
            float: right;
            padding-left: 1rem;
        }
        img {
            width: 100%;
            padding: 0;
            max-width: none;
        }
    }
    .side-text {
        width: 75%;
        padding: 0 2rem;
        &.left {
            float: left;
        }
        &.right {
            float: right;
        }
    }
}

.like-wrapper {
    .button {
        display: inline-block;
        &.is-pink {
            background: deeppink;
            color: #fff;
            border: none;
        }
    }
    .like-counter {
        background: limegreen;
        color: #fff;
        line-height: 10px;
        font-size: 14px;
        position: relative;
        display: inline-block;
        margin-left: 20px;
        padding: 10px 6px;
        text-align: center;
        min-width: 35px;
        height: 30px;
        border-radius: 3px;
        vertical-align: sub;
        margin-top: 7px;
        &:before {
            content: ' ';
            position: absolute;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 10px solid limegreen;
            left: -7px;
            top: 6px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .pricing-wrapper {
        .pricing-table {
            width: 100%;
            float: none;
            &.squashed {
                padding: 0;
                width: 100%;
                .pricing-plan {
                    width: 100%;
                    display: block;
                    margin-bottom: 1rem;
                    .plan-price {
                        width: 100%;
                        float: none;
                        text-align: left;
                    }
                }
            }
        }
        .other-plans {
            width: 100%;
            float: none;
            padding: 0;
        }
    }
    .container {
        width: 100%;
    }
    .side-menu {
        display: none;
    }
    .dropdown-wrapper {
        display: block;
    }
    .main-content {
        width: 100%;
        float: none;
        .side-text {
            padding: 0 10px;
        }
    }
    .feature-list {
        padding: 1rem;
    }
}

@media only screen and (max-width: 550px) {
    .main-content {
        .side-icon {
            width: 100%;
            &.left {
                float: none;
                padding-right: 0;
            }
            &.right {
                float: none;
                padding-left: 0;
            }
            img {
                width: 100%;
                padding: 0;
                max-width: 150px;
                display: block;
                margin: 0 auto;
            }
        }
        .side-text {
            width: 100%;
            &.left {
                float: none;
            }
            &.right {
                float: none;
            }
        }
    }
    .feature-list {
        padding: 10px;
    }
}
</style>
